<h2 mat-dialog-title>{{ data.id ? 'Update' : 'Create' }} Stock Item</h2>
<form
  [formGroup]="formGroup"
  mat-dialog-content
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
  xFormErrorBoundary
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field appearance="fill" class="col-6">
        <mat-label>SKU</mat-label>
        <input matInput type="text" formControlName="sku" autocomplete="off" />
        <mat-error xFormError="sku"></mat-error>
      </mat-form-field>

      <mat-form-field class="col-6" appearance="fill">
        <mat-label>Stock Category</mat-label>
        <x-model-autocomplete
          formControlName="stockCategoryId"
          [dataSource]="stockCategoryAutocompleteDatasource"
          [transformer]="stockCategoryIdTransformer"
          [displayWith]="stockCategoryAutocompleteDatasource.displayFn"
          [required]="true"
        ></x-model-autocomplete>
        <mat-error xFormError="stockCategoryId"></mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" autocomplete="off" />
        <mat-error xFormError="name"></mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-6">
        <mat-label>Unit</mat-label>
        <mat-select formControlName="unit">
          <mat-option value="UN">Each</mat-option>
        </mat-select>
        <mat-error xFormError="unit"></mat-error>
      </mat-form-field>

      <mat-form-field class="col-6">
        <mat-label>Stock Type</mat-label>
        <x-enum-select formControlName="type" enum="StockType" [nullable]="false"></x-enum-select>
      </mat-form-field>

      <mat-form-field class="col-12">
        <mat-label>Shipping Categories</mat-label>
        <x-data-autocomplete
          formControlName="shippingCategoryIds"
          [provider]="Providers.ShippingCategoryItemCollectionProvider"
          [multiple]="true"
        ></x-data-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Description</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="10"
          formControlName="description"
          autocomplete="off"
        ></textarea>
        <mat-error xFormError="description"></mat-error>
      </mat-form-field>
      <mat-expansion-panel class="col-12 mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>Dimensions</mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row">
          <mat-form-field class="col-6" appearance="fill">
            <mat-label>Length</mat-label>
            <input type="number" matInput formControlName="length" />
            <span matSuffix>mm</span>
            <mat-error xFormError="length"></mat-error>
          </mat-form-field>

          <mat-form-field class="col-6" appearance="fill">
            <mat-label>Width</mat-label>
            <input type="number" matInput formControlName="width" />
            <span matSuffix>mm</span>
            <mat-error xFormError="width"></mat-error>
          </mat-form-field>

          <mat-form-field class="col-6" appearance="fill">
            <mat-label>Height</mat-label>
            <input type="number" matInput formControlName="height" />
            <span matSuffix>mm</span>
            <mat-error xFormError="height"></mat-error>
          </mat-form-field>

          <mat-form-field class="col-6" appearance="fill">
            <mat-label>Weight</mat-label>
            <input type="number" matInput formControlName="weight" />
            <span matSuffix>g</span>
            <mat-error xFormError="weight"></mat-error>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="col-12 mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>Location</mat-panel-title>
        </mat-expansion-panel-header>

        <div formArrayName="warehouseOptions">
          <div
            class="row"
            *ngFor="let option of warehouseOptions.controls; let i = index"
            [formGroupName]="i"
          >
            <mat-form-field class="col-12" appearance="fill">
              <mat-label>Location</mat-label>
              <span class="warehouse-name" matPrefix>
                {{ option.get('warehouseName')?.value }}:
              </span>
              <input type="text" matInput formControlName="location" />
              <mat-error xFormError="location"></mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>

<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button
    mat-flat-button
    type="submit"
    (click)="submit()"
    color="primary"
    [disabled]="formGroup.disabled"
  >
    {{ data.id ? 'Update' : 'Create' }}
  </button>
</div>
