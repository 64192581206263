<form [formGroup]="formGroup" novalidate autocomplete="off" (ngSubmit)="submit()">
  <h2 mat-dialog-title>Warehouse</h2>
  <x-form-panel xFormErrorBoundary>
    <mat-form-field class="col-6">
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" />
    </mat-form-field>
    <mat-form-field class="col-6">
      <mat-label>Code</mat-label>
      <input matInput type="text" formControlName="code" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Channels</mat-label>
      <x-data-select
        [provider]="providers.ChannelItemCollectionProvider"
        formControlName="channelIds"
        [multiple]="true"
      ></x-data-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Timezone</mat-label>
      <mat-select formControlName="timezone">
        <mat-option *ngFor="let tz of timezones" [value]="tz">{{ tz }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-6">
      <mat-label>Priority</mat-label>
      <input xOnlyPositiveNumber matInput type="number" formControlName="priority" min="0" />
    </mat-form-field>
    <mat-form-field class="col-6">
      <mat-label>Color</mat-label>
      <x-enum-select formControlName="color" enum="DashboardColor"></x-enum-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Address</mat-label>
      <x-address-input formControlName="address"></x-address-input>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Configuration</mat-label>
      <textarea
        formControlName="configuration"
        matInput
        xInputDense
        rows="4"
        cdkTextareaAutosize
        cdkAutosizeMinRows="4"
      ></textarea>
      <mat-hint>JSON format</mat-hint>
      <mat-error xFormError="configuration"></mat-error>
    </mat-form-field>
    <mat-slide-toggle formControlName="enabled">Enable</mat-slide-toggle>
  </x-form-panel>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="dialog.close()">Cancel</button>
    <button mat-button type="submit" color="primary">Save</button>
  </div>
</form>
