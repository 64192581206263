import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Timezone } from '@x/common/datetime';
import { CommonValidator } from '@x/common/form';
import {
  AddressService,
  IAddressInput,
  IWarehouseRowObject,
  WarehouseService,
} from '@x/ecommerce/domain-client';
import { ChannelItemCollectionProvider } from '@x/ecommerce/domain-data';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export type WarehouseFormDialogData = { id?: number };
export type WarehouseFormDialogResult = { id: number };

@Component({
  selector: 'x-warehouse-form-dialog',
  templateUrl: './warehouse-form-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarehouseFormDialogComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  timezones = Timezone.list();
  providers = {
    ChannelItemCollectionProvider,
  };

  formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(this.data.id ?? null),
    name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(32)]),
    code: new UntypedFormControl(null, [Validators.required, Validators.maxLength(32)]),
    priority: new UntypedFormControl(null, [Validators.required]),
    channelIds: new UntypedFormControl(null, [Validators.required]),
    timezone: new UntypedFormControl(null, [Validators.required]),
    address: new UntypedFormControl(null),
    configuration: new UntypedFormControl(null, CommonValidator.json()),
    color: new UntypedFormControl(null),
    enabled: new UntypedFormControl(true),
  });

  get addressVal() {
    return this.formGroup.get('address')?.value as IAddressInput;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: WarehouseFormDialogData,
    public dialog: MatDialogRef<WarehouseFormDialogComponent>,
    private warehouseService: WarehouseService,
    private addressService: AddressService,
  ) {}

  ngOnInit(): void {
    if (this.data.id) {
      this.warehouseService
        .fetchDetail(this.data.id)
        .pipe(
          takeUntil(this._destroy$),
          tap((value) => this.patchForm(value)),
        )
        .subscribe();
    }
  }

  submit() {
    this.formGroup.updateValueAndValidity();
    const { value, invalid } = this.formGroup;

    if (invalid) return;

    if (this.data.id) {
      this.warehouseService
        .update(value)
        .pipe(
          takeUntil(this._destroy$),
          tap(({ id }) => {
            this.dialog.close({ id });
          }),
        )
        .subscribe();
    } else {
      const { id, ...rest } = value;
      this.warehouseService
        .create({
          ...rest,
        })
        .pipe(
          takeUntil(this._destroy$),
          tap(({ id }) => {
            this.dialog.close({ id });
          }),
        )
        .subscribe();
    }
  }

  patchForm(v: IWarehouseRowObject): void {
    this.formGroup.patchValue({
      ...v,
      channelIds: v.channels.map(({ id }) => id),
      ...(v.address && {
        address: this.addressService.addressToAddressInput(v.address),
      }),
      ...(v.configuration && { configuration: JSON.stringify(v.configuration) }),
    });
  }

  addressUpdated(address: IAddressInput) {
    this.formGroup.patchValue({ address });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
