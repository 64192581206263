import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Operation, OperationObserverService } from '@x/common/operation';
import { PromptDialogService } from '@x/dashboard/dialog';
import { StockItemComponentService } from '@x/ecommerce/domain-client';
import {
  StockItemAutocompleteDataSource,
  StockItemIdTransformer,
  StockItemItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { CreateStockItemComponentInput, UpdateStockItemComponentInput } from '@x/schemas/ecommerce';
import { filter, finalize, Observable, switchMap, tap } from 'rxjs';

export interface StockItemComponentFormDialogData {
  id?: number;
  parentItemId?: number;
}

export type StockItemComponentFormDialogResult = {
  id?: number;
};

@Component({
  selector: 'x-stock-item-component-form-dialog',
  templateUrl: 'stock-item-component-form-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockItemComponentFormDialogComponent implements OnInit {
  Providers = {
    StockItemItemCollectionProvider,
  };

  quantityControl = new FormControl<number | null>(null, [Validators.required, Validators.min(1)]);
  componentItemIdControl = new FormControl<number | null>(null, [Validators.required]);

  formGroup = new UntypedFormGroup({
    componentItemId: this.componentItemIdControl,
    componentQuantity: this.quantityControl,
  });

  mutation$: Observable<Operation<any>>;

  constructor(
    private stockItemComponentService: StockItemComponentService,
    public stockItemDatasource: StockItemAutocompleteDataSource,
    public stockItemTransformer: StockItemIdTransformer,
    @Inject(MAT_DIALOG_DATA)
    public data: StockItemComponentFormDialogData,
    private snackBar: MatSnackBar,
    private prompt: PromptDialogService,
    private operations: OperationObserverService,
    private dialogRef: MatDialogRef<
      StockItemComponentFormDialogComponent,
      StockItemComponentFormDialogResult
    >,
  ) {}

  ngOnInit(): void {
    if (this.data.id) {
      this.componentItemIdControl.disable();
      this.stockItemComponentService
        .fetchItem(this.data.id)
        .pipe()
        .subscribe((item) => {
          this.formGroup.setValue({
            componentItemId: item.componentItem.id,
            componentQuantity: item.componentQuantity,
          });
        });
    } else if (!this.data.parentItemId) {
      console.warn('No id or parent item id provided');
      this.formGroup.disable();
      this.formGroup.setErrors({
        unknown_state: 'No id or parent item id provided',
      });
    }
  }

  submit() {
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      this.formGroup.disable();

      const value = this.formGroup.value;

      if (this.data.id) {
        const input: UpdateStockItemComponentInput = {
          id: this.data.id,
          componentQuantity: value.componentQuantity,
        };
        this.mutation$ = this.operations.observe(this.stockItemComponentService.update(input)).pipe(
          finalize(() => this.formGroup.enable()),
          tap((state) => {
            if (state.isSuccessState()) {
              this.dialogRef.close(state.data);
            }
            if (state.isErrorState()) {
              this.snackBar.open(state.error.message, 'OK');
            }
          }),
        );
      } else if (this.data.parentItemId) {
        const input: CreateStockItemComponentInput = {
          componentItemId: value.componentItemId,
          componentQuantity: value.componentQuantity,
          parentItemId: this.data.parentItemId,
        };
        this.mutation$ = this.operations.observe(this.stockItemComponentService.create(input)).pipe(
          tap((state) => {
            if (state.isSuccessState()) {
              this.dialogRef.close(state.data);
            }
            if (state.isErrorState()) {
              this.snackBar.open(state.error.message, 'OK');
            }
          }),
          finalize(() => this.formGroup.enable()),
        );
      }
    } else {
      console.warn(this.formGroup.errors);
    }
  }

  deleteComponent() {
    if (!this.data.id) return;

    this.mutation$ = this.operations
      .observe(
        this.prompt
          .confirm({
            message: 'Are you sure you want to delete this component?',
          })
          .pipe(
            filter((result) => result),
            switchMap(() => this.stockItemComponentService.delete(this.data.id!)),
          ),
      )
      .pipe(
        tap((state) => {
          if (state.isSuccessState()) {
            this.dialogRef.close({
              id: state.data,
            });
          }
          if (state.isErrorState()) {
            this.snackBar.open(state.error.message, 'OK');
          }
        }),
        finalize(() => this.formGroup.enable()),
      );
  }
}
