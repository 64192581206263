import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Operation, OperationObserverService } from '@x/common/operation';
import {
  IStockItemItemObject,
  IStockItemWarehouseOptionDetailObject,
  StockItemService,
  WarehouseService,
} from '@x/ecommerce/domain-client';
import {
  ProductVariantAutocompleteDatasource,
  ProductVariantIdTransformer,
  ShippingCategoryItemCollectionProvider,
  StockCategoryAutocompleteDatasource,
  StockCategoryIdTransformer,
} from '@x/ecommerce/domain-data';
import { StockType } from '@x/schemas/ecommerce';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

export type StockItemFormDialogData = {
  id?: number;
};

export type StockItemFormDialogResult = {
  id: number;
};

@Component({
  selector: 'x-stock-item-form-dialog',
  templateUrl: 'stock-item-form-dialog.component.html',
  styleUrls: ['./stock-item-form-dialog.component.scss'],
  providers: [
    StockCategoryAutocompleteDatasource,
    StockCategoryIdTransformer,
    ProductVariantAutocompleteDatasource,
    ProductVariantIdTransformer,
  ],
})
export class StockItemFormDialogComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  Providers = {
    ShippingCategoryItemCollectionProvider,
  };

  operation$ = new BehaviorSubject<Operation<IStockItemItemObject>>(Operation.create());

  duplicateSku: string | null = null;

  formGroup = new UntypedFormGroup({
    sku: new UntypedFormControl(null, [
      Validators.required,
      Validators.maxLength(32),
      (control: AbstractControl) => {
        if (this.duplicateSku && this.duplicateSku === control.value) {
          return { duplicate: true };
        }
        return null;
      },
    ]),
    name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]),
    stockCategoryId: new UntypedFormControl(null, [Validators.required]),
    productVariantIds: new UntypedFormControl([]),
    shippingCategoryIds: new UntypedFormControl([]),
    length: new UntypedFormControl(null, [Validators.min(1)]),
    width: new UntypedFormControl(null, [Validators.min(1)]),
    height: new UntypedFormControl(null, [Validators.min(1)]),
    weight: new UntypedFormControl(null, [Validators.min(1)]),
    unit: new UntypedFormControl('UN', [Validators.maxLength(4)]),
    description: new UntypedFormControl(null, [Validators.maxLength(2048)]),
    type: new UntypedFormControl(StockType.Physical),
    warehouseOptions: new UntypedFormArray([]),
  });

  get warehouseOptions(): UntypedFormArray {
    return this.formGroup.get('warehouseOptions') as UntypedFormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: StockItemFormDialogData,
    public dialog: MatDialogRef<StockItemFormDialogComponent>,
    private stockItemService: StockItemService,
    public readonly stockCategoryAutocompleteDatasource: StockCategoryAutocompleteDatasource,
    public readonly stockCategoryIdTransformer: StockCategoryIdTransformer,
    public readonly productVariantAutocompleteDatasource: ProductVariantAutocompleteDatasource,
    public readonly productVariantIdTransformer: ProductVariantIdTransformer,
    private readonly operations: OperationObserverService,
    private readonly warehouseService: WarehouseService,
  ) {}

  ngOnInit(): void {
    if (this.data.id) {
      //update
      this.operations
        .observe(
          combineLatest([
            this.stockItemService.fetchDetail(this.data.id),
            this.warehouseService.fetchAll(),
          ]),
        )
        .subscribe((res) => {
          if (res.isSuccessState()) {
            const [stockItem, warehouses] = res.data;
            let {
              sku,
              name,
              category,
              variants,
              length,
              width,
              height,
              weight,
              unit,
              description,
              shippingCategories,
              type,
              warehouseOptions,
            } = stockItem;

            this.formGroup.setValue({
              sku,
              name,
              stockCategoryId: category?.id ?? null,
              productVariantIds: variants.map((v) => v.id),
              length,
              width,
              height,
              weight,
              unit,
              description,
              shippingCategoryIds: shippingCategories.map((v) => v.id),
              type,
              warehouseOptions: [],
            });

            // Create a map of warehouse options keyed by warehouse ID
            const warehouseOptionsMap = new Map(warehouseOptions.map((o) => [o.warehouse.id, o]));

            for (let warehouse of warehouses) {
              const warehouseOption: IStockItemWarehouseOptionDetailObject | undefined =
                warehouseOptionsMap.get(warehouse.id);
              this.addWarehouseOption({ location: warehouseOption?.location ?? null, warehouse });
            }
          }
        });
    } else {
      //new
      this.operations.observe(this.warehouseService.fetchAll()).subscribe((res) => {
        if (res.isSuccessState()) {
          for (let warehouse of res.data) {
            this.addWarehouseOption({ warehouse });
          }
        }
      });
    }
  }

  /**
   * Adds a warehouse option to the warehouseOptions array.
   *
   * @param warehouseOption - The warehouse option to add.
   */
  addWarehouseOption(warehouseOption: any) {
    const group = new UntypedFormGroup({
      location: new UntypedFormControl(warehouseOption.location),
      warehouseId: new UntypedFormControl(warehouseOption.warehouse.id),
      warehouseName: new UntypedFormControl(warehouseOption.warehouse.name),
    });
    this.warehouseOptions.push(group);
  }

  submit() {
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();
    const { value, invalid } = this.formGroup;

    if (invalid) return;

    // Create a new object that excludes 'warehouseName' from 'warehouseOptions'
    const submitValue = {
      ...value,
      warehouseOptions: value.warehouseOptions.map((option: any) => {
        const { warehouseName, ...rest } = option;
        return rest;
      }),
    };

    const operation = this.data.id
      ? this.stockItemService.update({ id: this.data.id, ...submitValue })
      : this.stockItemService.create(submitValue);

    this.formGroup.disable();

    this.operations
      .observe(operation)
      .pipe(
        finalize(() => {
          this.formGroup.enable();
        }),
      )
      .subscribe((res) => {
        this.operation$.next(res);
        if (res.isErrorState()) {
          if (res.error.code === 'duplicate_entity_code') {
            this.duplicateSku = value.sku;
          }
        } else if (res.isSuccessState()) {
          this.dialog.close(res.data);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
