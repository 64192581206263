import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  StockCategoryCreateDialogComponent,
  StockCategoryCreateDialogData,
  StockCategoryCreateDialogResult,
} from '../components/stock-category-create-dialog/stock-category-create-dialog.component';
import {
  StockCategoryUpdateDialogComponent,
  StockCategoryUpdateDialogData,
  StockCategoryUpdateDialogResult,
} from '../components/stock-category-update-dialog/stock-category-update-dialog.component';

@Injectable()
export class StockCategoryDialogService {
  constructor(private dialogs: MatDialog) {}

  openStockCategoryCreateDialog() {
    return this.dialogs.open<
      StockCategoryCreateDialogComponent,
      StockCategoryCreateDialogData,
      StockCategoryCreateDialogResult
    >(StockCategoryCreateDialogComponent);
  }

  openStockCategoryUpdateDialog(id: number) {
    return this.dialogs.open<
      StockCategoryUpdateDialogComponent,
      StockCategoryUpdateDialogData,
      StockCategoryUpdateDialogResult
    >(StockCategoryUpdateDialogComponent, {
      width: '500px',
      data: { id },
    });
  }
}
