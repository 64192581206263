import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stockPriorityLabel',
})
export class StockPriorityLabelPipe implements PipeTransform {
  transform(value: number) {
    switch (true) {
      case value >= 4:
        return `Citical [${value}]`;
      case value >= 3:
        return 'Critical';
      case value >= 2:
        return 'High';
      case value >= 1:
        return 'Normal';
    }
    return 'Low';
  }
}
