<h3 mat-dialog-title>{{ data.id ? 'Update' : 'Create' }} Stock Lot</h3>
<form
  [formGroup]="formGroup"
  mat-dialog-content
  xFormErrorBoundary
  (ngSubmit)="submit()"
  id="stock_schedule_form"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field appearance="fill" floatLabel="always" class="col-6">
        <mat-label>Lot Quantity [UN]</mat-label>
        <input type="number" matInput formControlName="quantity" placeholder="&infin;" />
        <mat-hint>Number of stock units on hand</mat-hint>
        <mat-error xFormError="quantity"></mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-6">
        <mat-label>Warehouse</mat-label>
        <x-data-select
          [provider]="Providers.WarehouseItemCollectionProvider"
          formControlName="warehouseId"
        ></x-data-select>
        <mat-hint></mat-hint>
        <mat-error xFormError="name"></mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-6">
        <mat-label>Lot Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Available date" formControlName="availableAt" />
          <input matEndDate placeholder="Expiry date" formControlName="expiresAt" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-hint>Available - Expiry Date of the stock</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-6">
        <mat-label>
          <fa-icon icon="sort"></fa-icon>
          Lot Priority
        </mat-label>
        <mat-select formControlName="priority">
          <mat-option [value]="0">{{ 0 | stockPriorityLabel }}</mat-option>
          <mat-option [value]="1">{{ 1 | stockPriorityLabel }}</mat-option>
          <mat-option [value]="2">{{ 2 | stockPriorityLabel }}</mat-option>
          <mat-option [value]="3">{{ 3 | stockPriorityLabel }}</mat-option>
        </mat-select>
        <mat-error xFormError="priority"></mat-error>
        <mat-hint></mat-hint>
      </mat-form-field>

      <mat-slide-toggle class="col-3" formControlName="tracked">Tracked</mat-slide-toggle>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end" [xOperation]="mutation$ | async">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    color="primary"
    type="submit"
    form="stock_schedule_form"
    [disabled]="this.formGroup.disabled"
  >
    {{ data.id ? 'Update' : 'Create' }}
  </button>
</div>
