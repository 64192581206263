import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StockCategoryService } from '@x/ecommerce/domain-client';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export type StockCategoryUpdateDialogData = { id: number };
export type StockCategoryUpdateDialogResult = { id: number };

@Component({
  selector: 'x-stock-category-update-dialog',
  templateUrl: './stock-category-update-dialog.component.html',
  styleUrls: ['./stock-category-update-dialog.component.scss'],
})
export class StockCategoryUpdateDialogComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(this.data.id),
    name: new UntypedFormControl(null, [Validators.required]),
    description: new UntypedFormControl(null),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: StockCategoryUpdateDialogData,
    public dialog: MatDialogRef<StockCategoryUpdateDialogComponent>,
    private stockCategoryService: StockCategoryService,
  ) {}

  submit() {
    this.formGroup.updateValueAndValidity();
    const { value, invalid } = this.formGroup;

    if (invalid) return;

    this.stockCategoryService
      .update(value)
      .pipe(
        takeUntil(this._destroy$),
        tap(({ id }) => this.dialog.close({ id })),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnInit(): void {
    this.stockCategoryService
      .fetchById(this.data.id)
      .pipe(
        takeUntil(this._destroy$),
        tap((v) => this.formGroup.patchValue(v)),
      )
      .subscribe();
  }
}
