<h2 mat-dialog-title>Update Stock Category</h2>
<form
  [formGroup]="formGroup"
  mat-dialog-content
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
>
  <x-dashboard-form-layout>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" autocomplete="off" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        xInputDense
        rows="4"
        formControlName="description"
        cdkTextareaAutosize
        cdkAutosizeMinRows="4"
      ></textarea>
    </mat-form-field>
  </x-dashboard-form-layout>
</form>

<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button
    mat-button
    type="submit"
    [disabled]="formGroup.invalid"
    (click)="submit()"
    color="primary"
  >
    Update
  </button>
</div>
