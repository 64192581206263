import { LanguageDeclaration } from '@x/common/locale';

export const INVENTORY_LANGUAGE: LanguageDeclaration = {
  fulfilment: {
    state: {
      NEW: 'New',
      RESERVED: 'Reserved',
      ALLOCATED: 'Allocated',
      PACKED: 'Packed',
      UNALLOCATED: 'Unallocated',
      FULFILLED: 'Fulfilled',
      CANCELLED: 'Cancelled',
    },
    actions: {
      ship: 'Ship',
    },
  },
};
