import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { StockCategoryService } from '@x/ecommerce/domain-client';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export type StockCategoryCreateDialogData = {};
export type StockCategoryCreateDialogResult = {
  id: number;
};

@Component({
  selector: 'x-stock-category-create-dialog',
  templateUrl: './stock-category-create-dialog.component.html',
  styleUrls: ['./stock-category-create-dialog.component.scss'],
})
export class StockCategoryCreateDialogComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();

  formGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required]),
  });

  constructor(
    public dialog: MatDialogRef<StockCategoryCreateDialogComponent>,
    private stockCategoryService: StockCategoryService,
  ) {}

  submit() {
    this.formGroup.updateValueAndValidity();
    const { value, invalid } = this.formGroup;

    if (invalid) return;

    this.stockCategoryService
      .create(value)
      .pipe(
        takeUntil(this._destroy$),
        tap(({ id }) => this.dialog.close({ id })),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
