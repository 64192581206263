import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Operation, OperationObserverService } from '@x/common/operation';
import { coerceDateTime } from '@x/common/utils';
import { IStockLotItem, StockLotRefService, WarehouseService } from '@x/ecommerce/domain-client';
import { WarehouseItemCollectionProvider } from '@x/ecommerce/domain-data';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

export interface StockLotFormDialogData {
  id?: string;
  itemId?: number | null;
  warehouseId?: number | null;
}

export type StockLotFormDialogResult = IStockLotItem | undefined;

@Component({
  selector: 'x-stock-lot-form-dialog',
  templateUrl: 'stock-lot-form-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockLotFormDialogComponent implements OnInit {
  readonly Providers = {
    WarehouseItemCollectionProvider,
  };

  quantityControl = new UntypedFormControl(0, [Validators.required, Validators.min(0)]);

  formGroup = new UntypedFormGroup({
    warehouseId: new UntypedFormControl(this.data.warehouseId ?? null, [Validators.required]),
    itemId: new UntypedFormControl(this.data.itemId ?? null, [Validators.required]),
    quantity: this.quantityControl,
    priority: new UntypedFormControl(1, [Validators.required]),
    availableAt: new UntypedFormControl(null),
    expiresAt: new UntypedFormControl(null),
    tracked: new UntypedFormControl(true),
  });

  mutation$: Observable<Operation<any>>;

  constructor(
    private stockLotRefService: StockLotRefService,
    @Inject(MAT_DIALOG_DATA)
    public data: StockLotFormDialogData,
    private operations: OperationObserverService,
    private warehouseService: WarehouseService,
    private readonly dialogRef: MatDialogRef<StockLotFormDialogComponent, StockLotFormDialogResult>,
  ) {}

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(({ availableAt, expiresAt }) => {
      this.updateTrackedState();
    });

    if (this.data.id) {
      this.stockLotRefService
        .fetchDetail(this.data.id)
        .subscribe(({ warehouse, item, quantity, priority, availableAt, expiresAt, tracked }) => {
          availableAt = availableAt
            ? DateTime.fromISO(availableAt).setZone('utc', { keepLocalTime: true })
            : null;
          expiresAt = expiresAt
            ? DateTime.fromISO(expiresAt).setZone('utc', { keepLocalTime: true })
            : null;

          this.formGroup.setValue({
            quantity,
            priority,
            availableAt,
            expiresAt,
            tracked,
            warehouseId: warehouse.id,
            itemId: item.id,
          });
        });
      this.formGroup.get('warehouseId')?.disable();
      this.formGroup.get('itemId')?.disable();
    }
  }

  async submit() {
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      const value = this.formGroup.getRawValue();

      const warehouse = await this.operations.promise(
        this.warehouseService.fetchItem(value.warehouseId ?? this.data.warehouseId),
      );

      if (!warehouse.isSuccessState()) {
        throw new Error('Failed to load warehouse');
      }

      const availableAt = coerceDateTime(value.availableAt)?.setZone(warehouse.data.timezone, {
        keepLocalTime: true,
      });
      const expiresAt = coerceDateTime(value.expiresAt)?.setZone(warehouse.data.timezone, {
        keepLocalTime: true,
      });

      this.formGroup.disable();
      let operation$ = this.data.id
        ? this.stockLotRefService.update({
            id: this.data.id,
            ...value,
            warehouseId: undefined,
            itemId: undefined,
            availableAt,
            expiresAt,
          })
        : this.stockLotRefService.create({
            ...value,
            availableAt,
            expiresAt,
          });

      this.mutation$ = this.operations.observe(operation$).pipe(
        finalize(() => this.formGroup.enable()),
        tap((state) => {
          if (state.isSuccessState()) {
            this.dialogRef.close(state.data);
          }
        }),
      );
    } else {
      console.warn(this.formGroup.errors);
    }
  }

  updateTrackedState() {
    const tracked = this.formGroup.value.tracked;
    if (!tracked) {
      this.quantityControl.disable({ emitEvent: false });
    } else {
      this.quantityControl.enable({ emitEvent: false });
    }
  }
}
