import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  WarehouseFormDialogComponent,
  WarehouseFormDialogData,
  WarehouseFormDialogResult,
} from '../components/warehouse-form-dialog/warehouse-form-dialog.component';

@Injectable()
export class WarehouseDialogService {
  constructor(private dialogs: MatDialog) {}

  openWarehouseFormDialog(id?: number) {
    return this.dialogs.open<
      WarehouseFormDialogComponent,
      WarehouseFormDialogData,
      WarehouseFormDialogResult
    >(WarehouseFormDialogComponent, {
      width: '1000px',
      data: { id },
    });
  }
}
