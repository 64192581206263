import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { IStockCategoryObject } from '@x/ecommerce/domain-client';
import {
  StockCategoryAutocompleteDatasource,
  StockCategoryIdTransformer,
} from '@x/ecommerce/domain-data';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'x-stock-category-autocomplete',
  templateUrl: './stock-category-autocomplete.component.html',
  styleUrls: ['./stock-category-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StockCategoryAutocompleteDatasource, StockCategoryIdTransformer],
})
export class StockCategoryAutocompleteComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private _onChange: any = () => {};
  private _onTouched: any = () => {};
  private _destroy$ = new Subject<void>();

  @Input() label: string = 'Stock Category';

  control = new UntypedFormControl();

  constructor(
    public stockAutocompleteDatasource: StockCategoryAutocompleteDatasource,
    public stockCategoryIdTransformer: StockCategoryIdTransformer,
    @Optional()
    ngControl?: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  writeValue(stockCategory: IStockCategoryObject): void {
    if (!stockCategory) return;
    this.control.patchValue(stockCategory, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap((val) => {
          if (val) {
            this._onChange(val);
            this._onTouched();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
