<h3 mat-dialog-title>{{ data.id ? 'Update' : 'Add' }} Stock Component</h3>
<form
  [formGroup]="formGroup"
  mat-dialog-content
  xFormErrorBoundary
  (ngSubmit)="submit()"
  id="stock_schedule_form"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field appearance="fill">
        <mat-label>Stock Component</mat-label>
        <x-data-autocomplete
          [multiple]="false"
          [provider]="Providers.StockItemItemCollectionProvider"
          formControlName="componentItemId"
        ></x-data-autocomplete>
        <mat-hint></mat-hint>
        <mat-error xFormError="name"></mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Component Quantity</mat-label>
        <input type="number" matInput formControlName="componentQuantity" min="1" />
        <mat-error xFormError="quantity"></mat-error>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end" [xOperation]="mutation$ | async">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="warn" type="button" *ngIf="data.id" (click)="deleteComponent()">
    Remove
  </button>
  <button
    mat-flat-button
    color="primary"
    type="submit"
    form="stock_schedule_form"
    [disabled]="this.formGroup.disabled"
  >
    {{ data.id ? 'Update' : 'Create' }}
  </button>
</div>
