import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PromptDialogService } from '@x/dashboard/dialog';
import {
  StockItemComponentFormDialogComponent,
  StockItemComponentFormDialogData,
  StockItemComponentFormDialogResult,
} from '../components/stock-item-component-form-dialog/stock-item-component-form-dialog.component';
import {
  StockItemFormDialogComponent,
  StockItemFormDialogData,
  StockItemFormDialogResult,
} from '../components/stock-item-form-dialog/stock-item-form-dialog.component';
import {
  StockLotFormDialogComponent,
  StockLotFormDialogData,
  StockLotFormDialogResult,
} from '../components/stock-lot-form-dialog/stock-lot-form-dialog.component';
import {
  StockScheduleFormDialogComponent,
  StockScheduleFormDialogData,
  StockScheduleFormDialogResult,
} from '../components/stock-schedule-form-dialog/stock-schedule-form-dialog.component';

@Injectable()
export class StockItemDialogService {
  constructor(private dialogs: MatDialog, private prompts: PromptDialogService) {}

  openStockItemCreateDialog() {
    return this.dialogs.open<
      StockItemFormDialogComponent,
      StockItemFormDialogData,
      StockItemFormDialogResult
    >(StockItemFormDialogComponent, { data: {}, maxWidth: '500px' });
  }

  openStockItemUpdateDialog(id: number) {
    return this.dialogs.open<
      StockItemFormDialogComponent,
      StockItemFormDialogData,
      StockItemFormDialogResult
    >(StockItemFormDialogComponent, { data: { id }, maxWidth: '500px' });
  }

  openStockScheduleCreateDialog(data: { stockItemId: number | null; warehouseId?: number | null }) {
    return this.dialogs.open<
      StockScheduleFormDialogComponent,
      StockScheduleFormDialogData,
      StockScheduleFormDialogResult
    >(StockScheduleFormDialogComponent, { data, maxWidth: '800px' });
  }

  openStockScheduleUpdateDialog(id: number) {
    return this.dialogs.open<
      StockScheduleFormDialogComponent,
      StockScheduleFormDialogData,
      StockScheduleFormDialogResult
    >(StockScheduleFormDialogComponent, { data: { id } });
  }

  openStockLotCreateDialog(data: { itemId: number | null; warehouseId?: number | null }) {
    return this.dialogs.open<
      StockLotFormDialogComponent,
      StockLotFormDialogData,
      StockLotFormDialogResult
    >(StockLotFormDialogComponent, { data, maxWidth: '800px' });
  }

  openStockLotUpdateDialog(id: string) {
    return this.dialogs.open<
      StockLotFormDialogComponent,
      StockLotFormDialogData,
      StockLotFormDialogResult
    >(StockLotFormDialogComponent, { data: { id }, maxWidth: '800px' });
  }

  openStockLotAdjustmentDialog(id: string) {
    return this.prompts.enterNumber({
      title: 'Adjust Lot',
      okLabel: 'Adjust',
      placeholder: 'Amount + / -',
    });
  }

  openStockItemComponentCreateDialog(parentItemId: number) {
    return this.dialogs.open<
      StockItemComponentFormDialogComponent,
      StockItemComponentFormDialogData,
      StockItemComponentFormDialogResult
    >(StockItemComponentFormDialogComponent, { data: { parentItemId }, maxWidth: '800px' });
  }

  openStockItemComponentUpdateDialog(id: number) {
    return this.dialogs.open<
      StockItemComponentFormDialogComponent,
      StockItemComponentFormDialogData,
      StockItemComponentFormDialogResult
    >(StockItemComponentFormDialogComponent, { data: { id }, maxWidth: '800px' });
  }
}
