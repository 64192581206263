<h2 mat-dialog-title>Create Stock Category</h2>
<form
  [formGroup]="formGroup"
  mat-dialog-content
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
  xFormErrorBoundary
>
  <x-dashboard-form-layout>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" autocomplete="off" />
    </mat-form-field>
  </x-dashboard-form-layout>
</form>

<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" (click)="submit()" color="primary">Create</button>
</div>
