<h3 mat-dialog-title>{{ data.id ? 'Update' : 'Create' }} Stock Schedule</h3>
<form
  [formGroup]="formGroup"
  mat-dialog-content
  xFormErrorBoundary
  (ngSubmit)="submit()"
  id="stock_schedule_form"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field appearance="fill" class="col-8">
        <mat-label>Schedule Name</mat-label>
        <input type="text" matInput formControlName="name" />
        <mat-hint></mat-hint>
        <mat-error xFormError="name"></mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-4">
        <mat-label>Warehouse</mat-label>
        <x-model-autocomplete
          [dataSource]="warehouseDatasource"
          [transformer]="warehouseTransformer"
          [displayWith]="warehouseDatasource.displayFn"
          formControlName="warehouseId"
        ></x-model-autocomplete>
        <mat-hint></mat-hint>
        <mat-error xFormError="name"></mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-8">
        <mat-label>Schedule Interval</mat-label>
        <x-cron-input #intervalInput formControlName="interval"></x-cron-input>
        <x-cron-input-toggle matSuffix [for]="intervalInput"></x-cron-input-toggle>
        <mat-error xFormError="interval"></mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-4">
        <mat-label>Schedule Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" formControlName="startAt" />
          <input matEndDate placeholder="End date" formControlName="endAt" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-8">
        <mat-label>Lot Shelf Life</mat-label>
        <x-duration-input-control
          formControlName="shelfLife"
          [format]="['mth', 'wk', 'd', 'hr']"
        ></x-duration-input-control>
        <mat-hint>Shelf-life duration, empty indicates no-expiry</mat-hint>
        <mat-error xFormError="shelfLife"></mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-4">
        <mat-label>
          <fa-icon icon="sort"></fa-icon>
          Lot Priority
        </mat-label>
        <mat-select formControlName="priority">
          <mat-option [value]="0">{{ 0 | stockPriorityLabel }}</mat-option>
          <mat-option [value]="1">{{ 1 | stockPriorityLabel }}</mat-option>
          <mat-option [value]="2">{{ 2 | stockPriorityLabel }}</mat-option>
          <mat-option [value]="3">{{ 3 | stockPriorityLabel }}</mat-option>
        </mat-select>
        <mat-error xFormError="priority"></mat-error>
        <mat-hint></mat-hint>
      </mat-form-field>
      <mat-form-field appearance="fill" floatLabel="always" class="col-12">
        <mat-label>Lot Quantity [UN]</mat-label>
        <input type="number" matInput formControlName="quantity" placeholder="&infin;" />
        <mat-hint>Units in each lot, empty for un-tracked</mat-hint>
        <mat-error xFormError="quantity"></mat-error>
      </mat-form-field>
      <mat-slide-toggle class="col-3" formControlName="enabled">Enabled</mat-slide-toggle>
      <mat-slide-toggle class="col-3" formControlName="tracked">Tracked</mat-slide-toggle>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end" [xOperation]="mutation$ | async">
  <ng-container *ngIf="warehouse$ | async as warehouse">
    <span class="mat-hint"></span>
  </ng-container>
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    color="primary"
    type="submit"
    form="stock_schedule_form"
    [disabled]="this.formGroup.disabled"
  >
    {{ data.id ? 'Update' : 'Create' }}
  </button>
</div>
