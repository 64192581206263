import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Operation, OperationObserverService } from '@x/common/operation';
import { coerceDateTime } from '@x/common/utils';
import {
  IStockScheduleItemObject,
  IWarehouseItemObject,
  StockScheduleService,
  WarehouseService,
} from '@x/ecommerce/domain-client';
import { WarehouseAutocompleteDataSource, WarehouseIdTransformer } from '@x/ecommerce/domain-data';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, finalize, map, startWith, tap } from 'rxjs/operators';

export interface StockScheduleFormDialogData {
  id?: number | null;
  stockItemId?: number | null;
  warehouseId?: number | null;
}

export type StockScheduleFormDialogResult = IStockScheduleItemObject | undefined;

@Component({
  selector: 'x-stock-schedule-form-dialog',
  templateUrl: 'stock-schedule-form-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WarehouseAutocompleteDataSource, WarehouseIdTransformer],
})
export class StockScheduleFormDialogComponent implements OnInit {
  quantityControl = new UntypedFormControl(null, [Validators.required, Validators.min(1)]);

  warehouseIdControl = new UntypedFormControl(this.data.warehouseId ?? null, [Validators.required]);

  formGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required]),
    warehouseId: this.warehouseIdControl,
    stockItemId: new UntypedFormControl(this.data.stockItemId ?? null, [Validators.required]),
    quantity: this.quantityControl,
    priority: new UntypedFormControl(1, [Validators.required]),
    shelfLife: new UntypedFormControl(null),
    startAt: new UntypedFormControl(DateTime.now()),
    endAt: new UntypedFormControl(null),
    interval: new UntypedFormControl('0 0 * * 1', [Validators.required]),
    tracked: new UntypedFormControl(true),
    enabled: new UntypedFormControl(false),
  });

  mutation$: Observable<Operation<any>>;
  warehouse$ = new BehaviorSubject<IWarehouseItemObject | null>(null);

  constructor(
    private stockScheduleService: StockScheduleService,
    private warehouseService: WarehouseService,
    public warehouseDatasource: WarehouseAutocompleteDataSource,
    public warehouseTransformer: WarehouseIdTransformer,
    @Inject(MAT_DIALOG_DATA)
    public data: StockScheduleFormDialogData,
    private operations: OperationObserverService,
    private dialogRef: MatDialogRef<
      StockScheduleFormDialogComponent,
      StockScheduleFormDialogResult
    >,
  ) {}

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe((v) => {
      this.updateTrackedState();
      this.updateWarehouse();
    });

    this.warehouseIdControl.valueChanges
      .pipe(
        map((v) => v.warehouseId),
        startWith(this.data.warehouseId),
        distinctUntilChanged(),
      )
      .subscribe(() => this.updateWarehouse());

    if (this.data.id) {
      this.stockScheduleService
        .fetchItem(this.data.id)
        .subscribe(
          ({
            name,
            warehouse,
            item,
            quantity,
            priority,
            shelfLife,
            startAt,
            endAt,
            interval,
            tracked,
            enabled,
          }) => {
            this.formGroup.setValue({
              name,
              warehouseId: warehouse.id,
              stockItemId: item.id,
              quantity,
              priority,
              shelfLife,
              startAt,
              endAt,
              interval,
              tracked,
              enabled,
            });
          },
        );
    }
  }

  submit() {
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();

    const warehouse = this.warehouse$.getValue();

    if (this.formGroup.valid) {
      const form = this.formGroup.value;
      const value = {
        ...form,
        startAt: coerceDateTime(form.startAt)
          ?.setZone(warehouse?.timezone ?? 'UTC')
          .startOf('day'),
        endAt: coerceDateTime(form.endAt)
          ?.setZone(warehouse?.timezone ?? 'UTC')
          .startOf('day'),
      };

      this.formGroup.disable();

      let operation$ = this.data.id
        ? this.stockScheduleService.update({
            id: this.data.id,
            ...value,
          })
        : this.stockScheduleService.create({
            ...value,
          });

      this.mutation$ = this.operations.observe(operation$).pipe(
        finalize(() => this.formGroup.enable()),
        tap((state) => {
          if (state.isSuccessState()) {
            this.dialogRef.close(state.data);
          }
        }),
      );
    } else {
      console.warn(this.formGroup.errors);
    }
  }

  updateTrackedState() {
    const tracked = this.formGroup.value.tracked;
    if (!tracked) {
      this.quantityControl.disable({ emitEvent: false });
    } else {
      this.quantityControl.enable({ emitEvent: false });
    }
  }

  updateWarehouse() {
    const id = this.warehouseIdControl.value;

    if (id) {
      this.warehouseService.fetchItem(id).subscribe((w) => this.warehouse$.next(w));
    } else {
      this.warehouse$.next(null);
    }
  }
}
